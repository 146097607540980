import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import useMediaQuery from '@mui/material/useMediaQuery'
import { GorillaBox } from '@procom-labs/atoms'
import { ActivityIndicator } from '@procom-labs/molecules'

import { MobileAccordion } from './candidate-mobile-accordion'
import { ContainerGrid, ScrollableBox } from './styled-components'

enum TabKeys {
  resume = 'resume',
  onePager = 'onePager',
  documents = 'documents',
  notes = 'notes',
  timeline = 'timeline',
}

const TabPanel: React.FC<{
  value: number
  index: number
  id: string
}> = (props) => {
  const { children, value, index, id, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-button-${id}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export const CandidateDetailsTabs: React.FC<{
  activeTab: number
  handleChangeTab: (event: React.SyntheticEvent, newValue: number) => void
  onePagerTab?: ReactElement
  notesTab?: ReactElement
  timelineTab?: ReactElement
  resumeTab?: ReactElement
  additionalDocumentsTab?: ReactElement
  hasNewDocument?: boolean
  disableTabs?: boolean
}> = ({
  activeTab,
  handleChangeTab,
  onePagerTab,
  notesTab,
  timelineTab,
  resumeTab,
  additionalDocumentsTab,
  hasNewDocument,
  disableTabs,
}) => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('main')
  const activeTabs = useMemo(() => {
    const tabsArr = [
      ...(onePagerTab
        ? [{ id: TabKeys.onePager, component: onePagerTab }]
        : []),
      { id: TabKeys.resume, component: resumeTab },
    ]
    if (additionalDocumentsTab) {
      tabsArr.push({
        id: TabKeys.documents,
        component: additionalDocumentsTab,
      })
    }
    if (notesTab) {
      tabsArr.push({
        id: TabKeys.notes,
        component: notesTab,
      })
    }
    if (timelineTab) {
      tabsArr.push({
        id: TabKeys.timeline,
        component: timelineTab,
      })
    }
    return tabsArr
  }, [
    additionalDocumentsTab,
    notesTab,
    onePagerTab,
    resumeTab,
    timelineTab,
    onePagerTab,
  ])

  return (
    <ContainerGrid
      item
      xs={12}
      lg={7.8}
      sx={{
        padding: { xs: 2.5, lg: 5 },
        mt: { xs: 2.5, lg: 0 },
        minHeight: '70vh',
        overflow: 'hidden',
      }}
    >
      {mobileView ? (
        <ScrollableBox
          sx={{
            py: 1,
          }}
        >
          {activeTabs.map(({ id, component }) => (
            <MobileAccordion
              key={`mobile-accordion-candidate-profile-details-${id}`}
              details={component}
            >
              <Typography variant="button" sx={{ fontSize: 15 }}>
                {t(`common.candidates.tabs.${id}`)}
              </Typography>
            </MobileAccordion>
          ))}
        </ScrollableBox>
      ) : (
        <ScrollableBox>
          <GorillaBox
            isLightMode
            isThemeBackgroundApplied
            sx={{
              p: 1,
              position: 'sticky',
              top: '0',
              zIndex: 1,
            }}
          >
            <Tabs value={activeTab} onChange={handleChangeTab}>
              {activeTabs.map(({ id }) => (
                <Tab
                  key={`tab-button-candidate-profile-details-${id}`}
                  label={
                    id === TabKeys.documents ? (
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        {t('common.candidates.tabs.documents')} &nbsp;
                        {hasNewDocument && <ActivityIndicator />}
                      </Box>
                    ) : (
                      t(`common.candidates.tabs.${id}`)
                    )
                  }
                  disabled={disableTabs}
                  id={`tab-button-${id}`}
                  aria-controls={`tabpanel-${id}`}
                />
              ))}
            </Tabs>
          </GorillaBox>
          <Box
            sx={{
              position: { lg: 'absolute' },
              width: { lg: '100%' },
            }}
          >
            {activeTabs.map(({ id, component }, index) => (
              <TabPanel
                key={`${id}-tab-candidate-profile-details`}
                value={activeTab}
                index={index}
                id={id}
              >
                {component}
              </TabPanel>
            ))}
          </Box>
        </ScrollableBox>
      )}
    </ContainerGrid>
  )
}
